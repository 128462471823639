<template>
    <div class="p-relative">
        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </Toolbar>
        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="status"
            v-bind:substatus.sync="srok"
        >
        </FilterPanel>

        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="entities"
            :item-class="itemRowBackground"
            :options.sync="options"
            :server-items-length="total"
            :items-per-page="10"
            :footer-props="{ itemsPerPageOptions:[10, 20, 30] }"
            :page.sync="options.page"
            @page-count="paginationPageCount = $event"
            class="elevation-1 customization-list-table pagination-list-table sticky-table"
            @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document', common: data.item.iscommon })"
        >
            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.controldate`]="{ item }">
                <div>{{dateFormat(item.controldate, 'DD.MM.YYYY')}}</div>
            </template>

            <template v-slot:[`item.authorname`]="{ item }">
                <v-workplace-chip
                    v-if="item.authorname"
                    :id="item.authorid"
                    :name="item.authorname"
                />
            </template>

            <template v-slot:[`item.svod`]="{ item }">
                <v-workplace-chip
                    v-if="item.svod"
                    :id="item.svodworkplaceid"
                    :name="item.svod"
                />
            </template>

            <template v-slot:[`item.controlstatus`]="{ item }">
                <StatusChip :status="getStatus(item)"/>
            </template>

            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>

        </v-data-table>
        <ReportPeriodDlg ref="ReportPeriodDlgRef" />
    </div>
</template>

<script>
import i18n from '@/i18n';
import { httpAPI } from "@/api/httpAPI";
import Axios from 'axios';
import _ from 'lodash';
import sys from '@/services/system';
import { mapActions } from 'vuex';
import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/saylau/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import StatusChip from '@/components/global/chips/StatusChip';
import ReportPeriodDlg from '@/components/global/dialogs/ReportPeriodDlg';

export default {
    name: "OrdersSvetofor",
    components: {
        Toolbar,
        SearchPanel,
        StatusChip,
        FilterPanel,
        ReportPeriodDlg
    },
    columnIndex: {
        "regnumber" : 5,
        "authorname" : 10,
        "theme": 7,
        "svod": 11,
        "controldate": 8,
        "executiontext": 13
    },
    computed:{
        headers() {
            let arr =  [
                { text: i18n.t("_№_пункта"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Автор_поручения"), value: 'authorname' },
                { text: i18n.t("Текст_поручения"), value: 'theme' },
                { text: i18n.t("Ответственный_исполнитель"), value: 'svod'},
                { text: i18n.t("Срок_исполнения"), value: 'controldate', width: '110px' },
                { text: i18n.t("Текст_исполнения"), value: 'executiontext' },
                { text: i18n.t("Статус"), value: 'controlstatus', width: '150px', sortable: false, class: 'status-col' }
            ]
            return arr;
        },
        search: {
            get: function() {
                return this.$store.getters[`orders/lists/svetofor/getSearch`];
            },
            set: _.debounce(function(v) {
                this.$store.commit(`orders/lists/svetofor/SET_TABLE_OPTION_PAGE`, 1);
                this.$store.commit(`orders/lists/svetofor/SET_SEARCH`, v);
            }, 500)
        },
        status: {
            get: function() {
                return this.$store.getters[`orders/lists/svetofor/getStatus`];
            },
            set: _.debounce(function(v) {
                this.$store.commit(`orders/lists/svetofor/SET_TABLE_OPTION_PAGE`, 1);
                this.$store.commit(`orders/lists/svetofor/SET_STATUS`, v);
            }, 500)
        },
        srok: {
            get: function() {
                return this.$store.getters[`orders/lists/svetofor/getSrok`];
            },
            set: function(v) {
                if (this.status == 1 && v){
                    this.$store.commit(`orders/lists/svetofor/SET_TABLE_OPTION_PAGE`, 1);
                    this.$store.commit(`orders/lists/svetofor/SET_SROK`, v);
                }
            }
        },
        options: {
            get: function() {
                return this.$store.getters[`orders/lists/svetofor/getTableOptions`];
            },
            set: function(newValue) {
                this.$store.commit(`orders/lists/svetofor/SET_TABLE_OPTION`, newValue);
            }
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                ProtocolControlState: this.status,
                ExecutionStatus: this.srok
            }
        },
        avaibleStatuses() {
            return this.$store.getters['documents/references/GetAvaibleStatuses'];
        },
    },
    data: () => {
        return {
            loading: true,
            cancellationTokenSorce: null,
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
            filterDataSource: null,
        }
    },
    methods: {
        ...mapActions('orders', ['updateCounter']),
        getStatus(item){
            return this.avaibleStatuses[item.controlstatus] ?? item.controlstatus;
        },
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        onToolbarClick(event, btn){
            this[btn.Action](btn);
        },
        async Refresh() {
            await this.loadFilters();
            await this.getData(true);
        },
        async Export(){
            let reportItem = { type: 'Protocol.Svetofor.List', text: 'Экспорт_списка_поручений_за_период', param: 'period-param', allowPDF: false };
            let report = await this.$refs.ReportPeriodDlgRef.open(reportItem);
            let filter = {
                From: report.param.From,
                To: report.param.To,
                OrderBy: [this.$options.columnIndex["controldate"], this.$options.columnIndex["regnumber"] ],
                OrderDir: [false, false],
            };
            let exportURI = `api/orders/svetoforexport?param=${JSON.stringify(filter)}&mode=${process.env.NODE_ENV}`;
            exportURI += `&token=${this.$store.getters['global/auth/getUserInfo']?.Token}`;

            this.$eventBus.$emit('on-get-report', {url: exportURI, text: `Экспорт списка поручений от ${this.$moment(report.param.From).format('DD.MM.YYYY')} до ${this.$moment(report.param.To).format('DD.MM.YYYY')}`, type: 'Xls' });
        },
        update: _.debounce(function (object) {            
            this.updateSource(object);
        }, 250),
        //async getData(isRefresh = false)
        getData: _.debounce(async function(isRefresh) 
        {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();

            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `/api/orders/list?collection=Protocol.Svetofor&filter=${this.getTableFilterObject()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.Payload?.data ?? [];
            this.total = response?.data?.Payload?.total ?? 0;

            this.loading = false;

            this.updateCounter();
        }, 200),
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, ProtocolControlState, ExecutionStatus } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "controldate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn];
            let filter = {
                ProtocolControlState,
                SearchStr,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage,
            };
            if (ProtocolControlState == 1){
                filter.ExecutionStatus = ExecutionStatus;
            }
            return JSON.stringify(filter);
        },
        async loadFilters() {
            let filterResponse = null;
            filterResponse = await httpAPI({
                url: `api/orders/filter?collection=Protocol.Svetofor`,
                method: 'GET',
            });

            this.filterDataSource = filterResponse ? filterResponse.data.Payload : null;
        },
        dateFormat: sys.dateFormat,
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
    async created() {
        let menu = [
            {
                "Action": "Export",
                "Icon": "doc_send",
                "Items": null,
                "Text": "Экспорт_списка",
                "ToolTip": "Экспорт_полного_списка_поручений",
                "Type": "Documents",
                Localize: true
            },
            {
                "Action": "Refresh",
                "Icon": "refresh",
                "Items": null,
                "Text": "Обновить",
                "ToolTip": "Обновить_форму",
                "Type": null,
                Localize: true
            }
        ];
        this.$store.commit('documents/toolbar/SET_MENU', { menu });
        this.loadFilters();
    },
}
</script>
